<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>{{ formTitle }}</v-card-title>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'Relations' }" color="green" rounded
                >
                {{ $t('Cancel')}}
                </v-btn>
                <v-btn
                :disabled="!valid"
                color="primary"
                rounded
                @click="save"
                >
                {{$t('Save')}}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.name"
                            :label="$t('Name')"
                            :rules="rules.name"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-text-field
                            v-model="item.contactPerson"
                            :label="$t('Contact Person')"
                            :rules="rules.contactPerson"
                            required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-select
                            :items="users"
                            item-text="fullName"
                            item-value="id"
                            v-model="item.accountManagerId"
                            :label="$t('Account Manager')"
                            :rules="rules.accountManagerId"
                            required
                            ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-select
                            :items="relStatuses"
                            item-text="name"
                            item-value="value"
                            v-model="item.relationStatus"
                            :label="$t('Status')"
                            :rules="rules.relationStatus"
                            required
                            >
                                <template v-slot:item="{item}">
                                    {{$t(item.name)}}
                                </template>
                            </v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-select
                            :items="ClientTypes"
                            item-text="name"
                            item-value="id"
                            v-model="item.clientTypeId"
                            :label="$t('Client Type')"
                            :rules="rules.clientTypeId"
                            required
                            ></v-select>
                        </v-col>

                        <v-col
                        v-for="customField in customFields" v-bind:key="customField.id"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-text-field
                                v-if="customField.type==0"
                                v-model.number="customField.value"
                                type="number"
                                :label="customField.name"
                                class=""
                                :ref="customField.id"
                                :rules="rules.customField"
                            ></v-text-field>
                            <v-text-field
                                v-if="customField.type==1"
                                v-model="customField.value"
                                type="text"
                                :label="customField.name"
                                class=""
                                :ref="customField.id"
                                :rules="rules.customField"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {

    data() {
        return {
            valid: true,
            item: {},
            ClientTypes:[],
            relStatuses:[],
            CFitems:[],
            customFields:[],
            users:[],
            id:"",
            loading:false,
            rules: {
                name:[
                    value => !!value || this.$t('Required.'),
                ],
                contactPerson:[
                    value => !!value || this.$t('Required.'),
                ],

                relationStatus:[
                    value => !!value || value == 0 || this.$t('Required.'),
                ],
                clientTypeId:[
                    value => !!value || this.$t('Required.'),
                ],
            },
        }
    },

    computed: {
        formTitle () {
            return this.id == "" ? this.$t('Create New Relation') : this.$t('Edit Relation')
        },
    },

    created: function()
    {
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getItem();
        }else{
            this.CFfetchItems()
        }
        this.fetchUsers()
        this.fetchrelStatuses()
        this.fetchClientTypes()
    },

    methods: {

        fetchUsers()
        {
            let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=false';
            this.axios.get(uri).then((response) => {
                let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=false';
                this.axios.get(uri2).then((response) => {
                    this.users = response.data.filter(function(user){
                        return user.role == 1 || user.role == 2;
                    });
                });
            });
        },

        fetchrelStatuses()
        {
            let uri2 = this.$urlPrefix + '/RelationStatus/GetAll';
            this.axios.get(uri2).then((response) => {
                this.relStatuses = response.data;
            });
        },

        fetchClientTypes()
        {
            let uri = this.$urlPrefix + '/ClientType/CountAll';
            this.axios.get(uri).then((response) => {
                let uri2 = this.$urlPrefix + '/ClientType/GetList?start=0&count='+response.data;
                this.axios.get(uri2).then((response) => {
                    this.ClientTypes = response.data;
                });
            });
        },

        CFfetchItems()
        {
            let uri = this.$urlPrefix + '/RelationCustomField/CountAll';
            this.axios.get(uri).then((response) => {
                let uri2 = this.$urlPrefix + '/RelationCustomField/GetList?start=0&count='+response.data;
                this.axios.get(uri2).then((response) => {
                    this.CFitems = response.data;
                    this.customFields = []
                    this.CFitems.forEach(CFitem =>{
                        this.customFields.push({id:CFitem.id,name:CFitem.name,type:CFitem.type,value:""})
                    })
                });
            });
        },

        getItem()
        {
            this.loading = true
            let uri = this.$urlPrefix + '/Relation/Get?id=' + this.id;
            this.axios.get(uri).then((response) => {
                this.item = response.data;
                this.customFields = this.item.customFields;
                this.loading = false
            });
        },
        save () {

            if(this.$refs.form.validate()){
                this.item.customFields = [];
                for(var i in this.customFields){
                    let customField = this.customFields[i];
                    customField.value = String(customField.value)
                    this.item.customFields.push(customField)
                }


                this.loading = true
                if (this.id != "") {
                    let uri = this.$urlPrefix + '/Relation/Update/';
                    this.axios.put(uri, this.item).then((response) => {
                        if(response.status == 204 || response.status == 200){
                                this.loading = false
                                this.$router.push({name: 'Relations', query: { page: this.$route.query.page }});
                        }
                    });
                } else {
                    let uri = this.$urlPrefix + '/Relation/Add';
                    this.axios.post(uri, this.item).then((response) => {
                        if(response.status == 200){
                            this.item = {}
                            this.loading = false
                            this.$router.push({name: 'Relations', query: { page: this.$route.query.page }});
                        }
                    });
                }
            }
        },
    }
}
</script>
